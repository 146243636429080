.root {
  flexGrow: 1;
  maxWidth: 345;
  width: 100%;
}

.media {
  paddingTop: '56.25%';
  width: 100%;
  height: 15vw;
}

.expand {
  transform: 'rotate(0deg)';
  marginLeft: 'auto';
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  });
}

.expandOpen {
  transform: 'rotate(180deg)',
}

.avatar {
  backgroundColor: red[500],
}
