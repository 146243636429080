/* NotificationBar CSS */

.Notification {
  text-align: right;
  width: 100%;
}

.NotificationBar {
  text-align: right;
  width: 100%;
  min-height: 64px;
  line-height: 0px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.NotificationBar .MessageBox {

}

.Notification::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 64px 64px 0 0;
  border-color: #eb0028 transparent transparent transparent;
  top: 0px;
  z-index: 99;
  display: flex;
}

.NotificationBar .NotificationTray {
  /*flex: 0 1 30%;*/
  display: flex;
  position: relative;
  min-width: 300px;
  flex: 0 1 675px;
  justify-content: flex-end;
  align-items: center;
}


.NotificationBar a:hover,
.NotificationBar a *:hover {
  color: #EEE;
}
