/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.root {
position: fixed;
top: 50%;
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%);
z-index: 1000;
}

/* Style the icon bar links */
.root a {
display: block;
text-align: center;
padding: 16px;
transition: all 0.3s ease;
color: white;
font-size: 18pt;
}

/* Style the social media icons with color, if you want */
.root a:hover {
background-color: #000;
}

.facebook {
background: #3B5998;
color: white;
}

.twitter {
background: #55ACEE;
color: white;
}

.instagram {
background: #DD2A7B;
color: white;
}

.linkedin {
background: #007bb5;
color: white;
}

.youtube {
background: #bb0000;
color: white;
}

.email {
background: #7887AB;
color: white;
}

.language {
color: #7887AB;
font-size: 36pt;
}
