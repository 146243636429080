.sponsorLogo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: 100%;
  height:175px;
}

.button {
  width: 100%;
  height: 100%;
}
