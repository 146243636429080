.root {
    position: relative;
    min-height: 300px;
    max-height: 800px;
    overflow: hidden;
}
  /* Add some content at the bottom of the video/page */
.Content {
  position: absolute;
  top: 0;
  color:#f1f1f1;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.SubContent {
  text-align: center;
  padding: 10px;
}
.SubContent p {
  color: #eeeeeead;
  font-size: 1.3rem;
}
