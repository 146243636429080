.button {
    font-weight: bold;
    margin-top: 30px;
    color: #f1f1f1;
    border: #f1f1f1 1px solid;
    padding: 10px 5px;
    background-color: hsla(0, 0%, 0%, 0);
    font-size: 1.25rem;
    border-radius: 5px;
}
.button:hover {
    background-color: #f1f1f1;
    color: #000000c0;
}

.Paper {
  width: 100%;
}
